import PropTypes from "prop-types";
import { Link } from "gatsby";

import { Container, Text, LogoIcon, getProperty } from "@gh/shared";

import * as styles from "./menu-categories.module.scss";

export const MenuCategories = ({ categories, activeValue, linkGenerator }) => (
  <Container>
    <nav className={styles.wrapper}>
      {categories.map((category) => {
        const state = { skipScroll: true };
        const value = getProperty(category, "value");
        const label = getProperty(category, "label");
        const className = `${styles.item} ${activeValue === value ? styles.active : ""}`;

        return (
          <Link key={value} to={linkGenerator(category)} className={className} state={state}>
            <LogoIcon className={styles.icon} />
            <Text size="s2" wrapper="span" transform="uppercase">
              {label}
            </Text>
          </Link>
        );
      })}
    </nav>
  </Container>
);

MenuCategories.defaultProps = {
  categories: [],
  activeValue: null,
  linkGenerator: () => "",
};

MenuCategories.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  activeValue: PropTypes.string,
  linkGenerator: PropTypes.func.isRequired,
};
