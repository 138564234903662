import { Fragment } from "react";
import { graphql } from "gatsby";

import { getProperty } from "@gh/shared";

import { useActivities, useActivityCategories } from "hooks";

import { Meta } from "./common/meta";
import { Intro } from "./activities/intro";
import { Items } from "./activities/items";

const Template = ({ data }) => {
  const activities = useActivities();
  const activityCategories = useActivityCategories();

  const meta = getProperty(data, "markdownRemark.frontmatter.meta");
  const intro = getProperty(data, "markdownRemark.frontmatter.activities.intro");
  const items = {
    items: getProperty(activities, "activities.items"),
    categories: getProperty(activityCategories, "activity_categories.items"),
  };

  return (
    <Fragment>
      <Meta data={meta} />
      <Intro data={intro} />
      <Items data={items} />
    </Fragment>
  );
};

export default Template;

export const query = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        path
        title
        meta {
          title
          description
        }
        activities {
          intro {
            title
            content
          }
        }
      }
    }
  }
`;
