import { Fragment, useMemo } from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";

import {
  Container,
  Section,
  getProperty,
  Title,
  Text,
  TipIconDE,
  TipIconEN,
  useLocale,
} from "@gh/shared";

import { useMeta } from "hooks";

import { EmptyState } from "../common/empty-state";
import { MenuCategories } from "../common/menu-categories";
import { ButtonPrev, ButtonNext } from "../common/nav-button";

import * as styles from "./items.module.scss";

const ITEMS_PER_PAGE = 6;

const ICON = {
  de: <TipIconDE className={styles.icon} />,
  en: <TipIconEN className={styles.icon} />,
};

export const Items = ({ data }) => {
  const meta = useMeta();
  const { locale } = useLocale();
  const { search, pathname } = useLocation();

  const items = getProperty(data, "items") || [];
  const categories = getProperty(data, "categories") || [];

  const pageKey = getProperty(meta, "meta.params.page");
  const categoryKey = getProperty(meta, "meta.params.category");
  const labelPrev = getProperty(meta, "meta.navigation.label_prev");
  const labelNext = getProperty(meta, "meta.navigation.label_next");
  const labelMore = getProperty(meta, "meta.navigation.label_more");

  const queryParams = useMemo(() => {
    const urlSearchParams = new URLSearchParams(search);

    return {
      category: urlSearchParams.get(categoryKey),
      page: urlSearchParams.get(pageKey),
    };
  }, [search]);

  const filteredItems = useMemo(
    () =>
      items.filter((item) => {
        const categories = getProperty(item, "categories") || [];
        return queryParams.category ? categories.includes(queryParams.category) : true;
      }),
    [items, queryParams.category]
  );

  const paginatedItems = useMemo(() => {
    const page = parseInt(queryParams.page) || 1;
    const start = (page - 1) * ITEMS_PER_PAGE;
    const end = page * ITEMS_PER_PAGE;

    return filteredItems.slice(start, end);
  }, [filteredItems, queryParams.page]);

  const pagination = useMemo(() => {
    const page = parseInt(queryParams.page) || 1;
    const totalPages = Math.ceil(filteredItems.length / ITEMS_PER_PAGE);

    const prev = page - 1 >= 1 ? page - 1 : null;
    const next = page + 1 <= totalPages ? page + 1 : null;

    return { enabled: prev || next, prev, next };
  }, [filteredItems.length, queryParams.page]);

  const categoryLinkGenerator = (category) => {
    const value = getProperty(category, "value");
    const urlSearchParams = new URLSearchParams({
      [categoryKey]: value,
    });

    return value ? `./?${urlSearchParams.toString()}` : ".";
  };

  const pageLinkGenerator = (page) => {
    const urlSearchParams = new URLSearchParams();

    if (queryParams.category !== null) {
      urlSearchParams.append(categoryKey, queryParams.category);
    }

    if (page > 1) {
      urlSearchParams.append(pageKey, page);
    }

    return Array.from(urlSearchParams).length > 0 ? `./?${urlSearchParams.toString()}` : ".";
  };

  return (
    <Fragment>
      <Section align="center" size="sm" noPaddingBottom={true}>
        <MenuCategories
          categories={categories}
          activeValue={queryParams.category}
          linkGenerator={categoryLinkGenerator}
        />
      </Section>
      <Section align="center">
        {paginatedItems.length ? (
          <Container>
            <div className={styles.items}>
              {paginatedItems.map((item) => {
                const path = getProperty(item, "path");
                const title = getProperty(item, "title");
                const description = getProperty(item, "description");
                const highlight = getProperty(item, "highlight");
                const image = getProperty(item, "image.childImageSharp.gatsbyImageData");

                return (
                  <div key={path} className={styles.item}>
                    <Link to={path} className={styles.link}>
                      <div className={styles.holder}>
                        <GatsbyImage image={image} alt={title} className={styles.image} />
                        {highlight && ICON[locale]}
                      </div>
                      <div className={styles.body}>
                        <Title wrapper="h3" size="s4" weight="w4" className={styles.title}>
                          {title}
                        </Title>
                        <Text>{description}</Text>
                      </div>
                    </Link>
                    <Link to={path} className={styles.more}>
                      <Text wrapper="span" size="s2" family="f2" transform="uppercase">
                        {labelMore}
                      </Text>
                    </Link>
                  </div>
                );
              })}
            </div>
            {pagination.enabled && (
              <div className={styles.nav}>
                <ButtonPrev
                  wrapper={Link}
                  label={labelPrev}
                  disabled={!pagination.prev}
                  to={pageLinkGenerator(pagination.prev)}
                />
                <ButtonNext
                  wrapper={Link}
                  label={labelNext}
                  disabled={!pagination.next}
                  to={pageLinkGenerator(pagination.next)}
                />
                <Helmet>
                  <link rel="canonical" href={`${pathname}${search}`} />
                  {pagination.prev && <link rel="prev" href={pageLinkGenerator(pagination.prev)} />}
                  {pagination.next && <link rel="next" href={pageLinkGenerator(pagination.next)} />}
                </Helmet>
              </div>
            )}
          </Container>
        ) : (
          <EmptyState />
        )}
      </Section>
    </Fragment>
  );
};

Items.defaultProps = {
  data: {},
};

Items.propTypes = {
  data: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
    categories: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  }),
};
