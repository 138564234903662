// extracted by mini-css-extract-plugin
export var body = "items-module--body--fHt2+";
export var breakpoint_lg = "75rem";
export var breakpoint_md = "64rem";
export var breakpoint_sm = "48rem";
export var breakpoint_xl = "90rem";
export var breakpoint_xs = "30rem";
export var breakpoint_xxl = "120rem";
export var breakpoint_xxs = "15rem";
export var color_primary = "#82c17a";
export var color_secondary = "#ece8e1";
export var holder = "items-module--holder--uqtjS";
export var icon = "items-module--icon--t8yPJ";
export var image = "items-module--image--qh1GO";
export var item = "items-module--item--827jF";
export var items = "items-module--items--4zp83";
export var link = "items-module--link--YJ25V";
export var more = "items-module--more--jPt+A";
export var nav = "items-module--nav--ax35E";
export var title = "items-module--title--I5wN4";