import PropTypes from "prop-types";

import { LinkButtonArrow, Container, Text, EmptyIcon, getProperty } from "@gh/shared";

import { useMeta } from "hooks";

import * as styles from "./empty-state.module.scss";

export const EmptyState = ({ text, label }) => {
  const meta = useMeta();

  const defaultText = getProperty(meta, "meta.empty_state.text");
  const defaultLabel = getProperty(meta, "meta.empty_state.label_button");

  return (
    <Container>
      <EmptyIcon className={styles.icon} />
      <Text className={styles.text}>{text || defaultText}</Text>
      <LinkButtonArrow to="." state={{ skipScroll: true }}>
        {label || defaultLabel}
      </LinkButtonArrow>
    </Container>
  );
};

EmptyState.defaultProps = {
  text: null,
  label: null,
};

EmptyState.propTypes = {
  text: PropTypes.string,
  label: PropTypes.string,
};
